<template>
  <div class="personal-center bg-dark">
    <el-image
      v-if="personal.cover"
      :src="personal.cover"
      @contextmenu="handleMouse"
      :fit="'cover'"
      class="cover-img"
    ></el-image>
    <div v-else class="default-bg">
      <el-image
        @contextmenu="handleMouse"
        :src="personal_bg"
        :fit="'cover'"
        class="cover-img"
      ></el-image>
    </div>
    <div class="personal-detail">
      <div class="detail-num">
        <ul>
          <li>
            粉丝<span>{{ personal.fans }}</span
            >|
          </li>
          <li>
            关注<span>{{ personal.guanzhu }}</span
            >|
          </li>
          <li>
            作品<span>{{ personal.resopurcenum }}</span
            >|
          </li>
          <li>
            人气<span>{{ personal.renqi }}</span>
          </li>
        </ul>
        <div class="upload-cover-btn" v-if="user_id == currentId">
          设置封面<el-upload
            drag
            action=""
            accept=".jpg,.png,.jpeg"
            :show-file-list="false"
            multiple
            :http-request="customUploadPicture"
          >
          </el-upload>
        </div>
      </div>
      <div class="detail-info">
        <el-avatar
          :size="111"
          :src="personal.headimage || defaultimg"
          v-if="personal.headimage"
        ></el-avatar>
        <el-avatar :size="111" icon="el-icon-user-solid" v-else></el-avatar>
        <div class="name">
          {{ personal.nickname }}
          <img
            src="@/assets/images/male.png"
            class="sex"
            v-if="personal.sex === '1'"
          />
          <img src="@/assets/images/female.png" class="sex" v-else />
        </div>
        <div class="address-box">
          <img v-if="personal.address" src="@/assets/images/address.png" />
          <span v-if="personal.address" class="address">{{
            personal.address
          }}</span>
          <div
            class="follow"
            v-if="isGuanzhu"
            @click="handleFollow(personal.id)"
          >
            已关注
          </div>
          <div
            class="follow"
            v-else
            style="cursor: pointer"
            @click="handleFollow(personal.id)"
          >
            <i class="iconfont el-icon-plus"></i>关注
          </div>
        </div>
        <div class="honor" v-if="personal.honor">{{ personal.honor }}</div>
        <div class="intro">{{ personal.description }}</div>
      </div>
      <div class="personal-works">
        <div class="picture">
          <div class="row result-item" v-if="pictureList.length > 0">
            <div
              class="img-block type-1"
              v-for="item in pictureList"
              :key="item.id"
            >
              <img
                @contextmenu="handleMouse"
                style="height: 334px;min-width: 100%;cursor:pointer"
                :src="item.resource_path"
                :fit="'cover'"
                lazy
                @click="handleDetail(item)"
              />
              <div class="grid-text">
                <div class="grid-oper">
                  <p class="name">{{ item.title }}</p>
                  <div>
                    <img
                      src="@/assets/images/collection-1.png"
                      @click="handleCollection(item.id)"
                    />
                    <img
                      src="@/assets/images/forward-1.png"
                      @click="handleShare(item)"
                    />
                    <img
                      v-if="item.is_zan"
                      src="@/assets/images/like-fill.png"
                      @click="handleLike(item.id, pictureList)"
                      class="m-l"
                    />
                    <img
                      v-else
                      src="@/assets/images/like-1.png"
                      @click="handleLike(item.id, pictureList)"
                      class="m-l"
                    />
                    <span>{{ item.fabulous }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="empty-style">
            <p>暂时还没有相关信息</p>
          </div>
        </div>
        <div>
          <el-pagination
            :current-page.sync="currentPage"
            @current-change="handlePersonalCurrentChange"
            :page-size="80"
            layout="prev, pager, next"
            :total="total"
            v-if="total > 0"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!--分享组件-->
    <poster ref="Poster"></poster>
  </div>
</template>

<script>
import poster from "@/components/sharePoster/index.vue";
import pageMixin from "@/mixins/tbPage";
import toLogin from "@/mixins/toLogin";
export default {
  name: "index",
  mixins: [pageMixin, toLogin],
  components: { poster },
  data() {
    return {
      query: {}, //评论默认参数
      currentId: localStorage.getItem("user_id"),
      personQuery: {}, //个人中心默认参数
      index: "0",
      isGuanzhu: false, //是否关注
      user_id: "", //页面所属个人中心id
      personal_bg: require("../../assets/images/cover.png"), //个人中心封面
      personal: {},
      activeTab: "1",
      pictureList: [],
      defaultimg: require("../../assets/images/headPortrait.png"),
      videoList: [],
      current: "",
      total: 0,
      currentPage: 1
    };
  },
  created() {
    const { type, id } = this.$route.query;
    console.log(type, id);
    this.type = type;
    this.user_id = id;
    this.query = {
      more_type: type,
      user_id: id
    };
    //调取个人信息列表
    this.getPage();
  },
  mounted() {},
  methods: {
    handleMouse(e) {
      e.preventDefault();
    },
    customUploadPicture(data) {
      const formData = new FormData();
      formData.append("file", data.file);
      this.$api.postUpImages(formData).then(res => {
        if (res.data.code === 1) {
          console.log(res);
          this.personal.cover = res.data.data.yuantu;
          //调取保存接口
          this.$api
            .editUserinfo({ user_id: this.user_id, cover: this.personal.cover })
            .then(res => {
              console.log(999, res);
            });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleDetail(item) {
      this.$router.push({
        name: "picturePreview",
        query: { id: item.id }
      });
    },
    handleFollow(other_user_id) {
      console.log("添加关注");
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      const user_id = this.user_id; //当前用户id
      this.$api.postFollow({ user_id, other_user_id }).then(res => {
        if (res.data.code === 1) {
          this.$message.success(res.data.msg);
          this.isGuanzhu = res.data.data.is_follow;
          if (this.isGuanzhu) {
            this.personal.fans = this.personal.fans + 1;
          } else {
            this.personal.fans = this.personal.fans - 1;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //个人中心信息列表
    async getList(params) {
      const { data } = await this.$api.getLookMorePictureList(params);
      if (data.code === 1) {
        this.personal = data.data.userinfo;
        this.total = data.data.resource_count;
        this.pictureList = data.data.resource_info;
        this.isGuanzhu = data.data.userinfo.is_guanzhu;
      }
    },
    mouseenter(id) {
      this.current = id;
    },
    mouseleave() {
      this.current = "";
    },
    async handleCollection(id) {
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      const { data } = await this.$api.postShouCang({
        resource_id: id,
        user_id: this.user_id
      });
      if (data.code === 1) {
        this.$message({
          message: data.msg,
          type: "success"
        });
      } else {
        this.$message({
          message: data.msg,
          type: "warning"
        });
      }
    },
    async handleShare(info) {
      console.log(99999999, info);
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      const qrContent =
        window.location.origin + "#/picture/preview?id=" + info.id + "";
      const posterInfo = {
        nickname: info.nickname,
        headimage: info.headimage,
        user_description: info.user_description,
        url: info.resource_fimage || info.resource_path,
        title: info.title,
        description: info.description,
        resource_id: info.id,
        content: qrContent
      };
      this.$refs["Poster"].show(posterInfo);
      const { data } = await this.$api.postZhuanFa({
        resource_id: info.id,
        user_id: this.user_id
      });
      if (data.code === 1) {
        console.log(data.msg);
      }
    },
    async handleLike(id, list) {
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      console.log(789, id);
      const { data } = await this.$api.postClickZan({
        resource_id: id,
        user_id: this.user_id
      });
      const resource = list.filter(i => i.id === id)[0];
      if (data.code === 1) {
        this.$message({
          message: data.msg,
          type: "success"
        });
        resource.fabulous = resource.fabulous + 1;
      } else {
        this.$message({
          message: data.msg,
          type: "warning"
        });
        resource.fabulous = resource.fabulous - 1;
      }
      resource.is_zan = !resource.is_zan;
    }
  }
};
</script>

<style scoped lang="scss">
.personal-center {
  padding-bottom: 172px;
  .cover-img {
    width: 100%;
    height: 742px;
  }
  .default-bg {
    width: 100%;
    height: 742px;
    background: #cdd5f0;
  }
  .personal-detail {
    margin: 0 96px;
    position: relative;
    .detail-num {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 300;
      color: #ffffff;
      ul {
        display: flex;
        li {
          margin-left: 10px;
          font-family: PingFangSC-Light;
          span {
            margin: 0 10px 0 5px;
          }
        }
      }
      .upload-cover-btn {
        border: 2px solid #ffffff;
        border-radius: 18px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
        position: absolute;
        right: 0;
        z-index: 10;
        height: 28px;
        line-height: 24px;
        width: 84px;
        text-align: center;
        ::v-deep .el-upload-dragger {
          background: none;
          border-radius: 6px;
          border: none;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          overflow: hidden;
        }
      }
    }
    .detail-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: -78px;
      width: 100%;
      .name {
        position: relative;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-top: 20px;
        .sex {
          width: 16px;
          height: 14px;
          position: absolute;
          top: 5px;
          right: -26px;
        }
      }
      .honor {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #ac5241;
        margin: 17px 0 0;
      }
      .intro {
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #ffffff;
        margin-top: 49px;
      }
      .address-box {
        display: flex;
        margin: 22px 0 0px;
        img {
          width: 11px;
          height: 15px;
        }
        .address {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ffffff;
          margin: 0 14px 0 9px;
        }
        .follow {
          padding: 3px 8px;
          background: #e60021;
          border-radius: 8px;
          font-size: 12px;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          color: #ffffff;
          height: 18px;
        }
      }
      .el-avatar--icon {
        border: 2px solid #ffffff;
      }
    }
    .personal-works {
      margin-top: 220px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .tabs {
        display: flex;
        justify-content: center;
        background: #242424;
        border-radius: 17px;
        width: 100%;
        ul {
          display: flex;
          li {
            margin: 0 52px;
            padding: 5px 0;
            cursor: pointer;
            height: 34px;
            font-size: 23px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #ffffff;
            .line {
              width: 85%;
              height: 6px;
              background: #e60021;
              border-radius: 10px;
              margin-top: 20px;
              position: relative;
              &:after {
                content: " ";
                position: absolute;
                right: -8px;
                top: 0;
                width: 6px;
                height: 6px;
                background: #e60021;
                border-radius: 6px;
              }
            }
          }
        }
      }
      .picture {
        width: 100%;
      }
    }
  }
  .type-1 {
    height: 239px;
  }
  .img-block {
    height: 334px;
  }
  .result-item {
    display: flex;
    flex-wrap: wrap;
    padding: 60px 0;
    margin: 0;
    width: 100%;
    &:after {
      content: "";
      flex-grow: 99999;
    }
    .img-block {
      flex-grow: 1;
      transition: transform 0.3s ease;
      margin: 5px;
      height: 334px;
      overflow: hidden;
      position: relative;
      .grid-text {
        display: none;
      }
      .grid-oper {
        position: absolute;
        bottom: 0;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.65) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 47px;
        align-items: flex-end;
        padding: 0 12px 10px;
        font-size: 14px;
        .name {
          width: 50%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ffffff;
        }
        img {
          height: 17px;
          cursor: pointer;
          margin-left: 8px;
        }
        .m-l {
          margin-left: 8px;
          height: 19px;
        }
        span {
          margin-left: 5px;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ffffff;
          position: relative;
          top: -3px;
        }
      }
      &:hover {
        .grid-text {
          display: block;
        }
      }
    }
  }
}
.empty-style {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
